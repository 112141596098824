<template>
    <div>
        <!-- <v-dialog
            v-model="loading"
            hide-overlay
            persistent
            width="300"
            >
            <v-card
            color="primary"
            dark
            >
            <v-card-text>
                Carregando Informações...
                <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
                ></v-progress-linear>
            </v-card-text>
            </v-card>
        </v-dialog>
        <div >
        <v-dialog
            v-model="modalNaoPermitido"
            width="500"
            >
            <v-card>
                <v-card-title class="text-h5 red" dark style="color:white; font-family: Arial, Helvetica, sans-serif; background-color: red;">
                    Sem Permissão
                </v-card-title>

                <v-card-text class="mt-5" style="font-family: Arial, Helvetica, sans-serif; margin-top: 20px;">
                    Perfil de Acesso sem permissão, contate um administrador
                </v-card-text>
            </v-card>
            </v-dialog>
        </div>
        -->
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    computed: {
        loading() {
            return this.$store.state.loading;
        },
        modalNaoPermitido: {
            get() {
                return this.$store.state.modalNaoPermitido;
            },
            set(newValue) {
                this.$store.state.modalNaoPermitido = newValue;
            }
        }
    },
    watch: {
        modalNaoPermitido(val) {
        }
    },
}
</script>

<style>
html{
    height:100%;
    overflow:auto
}

body{
    height: 100%;
    /* overflow: scroll; */
}
.calendario .mx-table-date .today{
    color: #f1892c !important;
    border: #f1892c 1px solid !important;
    border-radius: 50% !important;
}


.calendario .mx-calendar-content .cell.active{
    background-color: #f1892c !important;
    color: white !important;
}


</style>
