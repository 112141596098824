import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

import financeiroRoutes from './financeiro'

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        meta: { requiresAuth: true },
        component: () => import('@templates/default/Index'),
        children: [
            ...financeiroRoutes,
            {
                path: '',
                name: 'Home',
                component: () => import('@pages/Home/Home')
            },
            {
                path: 'home/:setor_id',
                props: true,
                name: 'HomeSetor',
                component: () => import('@pages/Home/HomeSetor')
            },
            {
                path: 'home/cliente/:etapa',
                name: 'Etapa',
                component: () => import('@pages/Home/Etapa')
            },
            {
                path: 'usuario',
                name: 'Usuario',
                meta: {
                    title: "Usuários",
                    permissions: ['usuario_visualizar']
                },
                component: () => import('@pages/Usuario/Usuario')
            },
            {
                path: 'usuario/create',
                name: 'UsuarioCreate',
                meta: {
                    title: "Cadastro de Usuário",
                    permissions: ['usuario_inserir']
                },
                component: () => import('@pages/Usuario/UsuarioCreate')
            },
            {
                path: 'usuario/edit/:id',
                props: true,
                name: 'UsuarioEdit',
                meta: {
                    title: "Edição de Usuário",
                    permissions: ['usuario_editar']
                },
                component: () => import('@pages/Usuario/UsuarioEdit')
            },
            {
                path: 'usuario/setor/:id',
                props: true,
                name: 'UsuarioSetor',
                meta: {
                    title: "Edição de Usuário - Setor",
                    permissions: ['usuario_visualizar']
                },
                component: () => import('@pages/Usuario/UsuarioSetor')
            },
            {
                path: 'usuario/show/:id',
                props: true,
                name: 'UsuarioShow',
                meta: {
                    title: "Visualizar Usuário",
                    permissions: ['usuario_visualizar']
                },
                component: () => import('@pages/Usuario/UsuarioShow')
            },

            {
                path: 'cliente/:tipo?',
                props: true,
                name: 'Cliente',
                meta: {
                    title: "Clientes",
                    permissions: ['cliente_visualizar']
                },
                component: () => import('@pages/Cliente/Cliente')
            },
            {
                path: 'cliente/create',
                name: 'ClienteCreate',
                meta: {
                    title: "Cadastro de Cliente",
                    permissions: ['cliente_inserir']
                },
                component: () => import('@pages/Cliente/ClienteCreate')
            },
            {
                path: 'cliente/edit/:id',
                props: true,
                name: 'ClienteEdit',
                meta: {
                    title: "Edição de Cliente",
                    permissions: ['cliente_editar']
                },
                component: () => import('@pages/Cliente/ClienteEdit')
            },
            {
                path: 'cliente/show/:id',
                props: true,
                name: 'ClienteShow',
                meta: {
                    title: "Visualizar Cliente",
                    permissions: ['cliente_visualizar']
                },
                component: () => import('@pages/Cliente/ClienteShow')
            },
            {
                path: 'suporte',
                name: 'Suporte',
                meta: {
                    title: "Suporte",
                    permissions: ['suporte_visualizar']
                },
                component: () => import('@pages/Suporte/Suporte')
            },
            {
                path: 'suporte/create',
                name: 'SuporteCreate',
                meta: {
                    title: "Cadastro de Suporte",
                    permissions: ['suporte_inserir']
                },
                component: () => import('@pages/Suporte/SuporteCreate')
            },
            {
                path: 'suporte/show/:id',
                props: true,
                name: 'SuporteShow',
                meta: {
                    title: "Visualizar de Suporte",
                    permissions: ['suporte_visualizar']
                },
                component: () => import('@pages/Suporte/SuporteShow')
            },
            {
                path: 'suporte/edit/:id',
                props: true,
                name: 'SuporteEdit',
                meta: {
                    title: "Edição de Suporte",
                    permissions: ['suporte_editar']
                },
                component: () => import('@pages/Suporte/SuporteEdit')
            },
            {
                path: 'grupo',
                name: 'Grupo',
                meta: {
                    title: "Grupos e Sub-grupos",
                    permissions: ['grupo_visualizar']
                },
                component: () => import('@pages/Grupo/Grupo')
            },
            {
                path: 'grupo/create',
                name: 'GrupoCreate',
                meta: {
                    title: "Cadastro de Grupo",
                    permissions: ['grupo_inserir']
                },
                component: () => import('@pages/Grupo/GrupoCreate')
            },
            {
                path: 'grupo/edit/:id',
                props: true,
                name: 'GrupoEdit',
                meta: {
                    title: "Edição de Grupo",
                    permissions: ['grupo_editar']
                },
                component: () => import('@pages/Grupo/GrupoEdit')
            },
            {
                path: 'grupo/show/:id',
                props: true,
                name: 'GrupoShow',
                meta: {
                    title: "Configurações de Grupo",
                    permissions: ['grupo_visualizar']
                },
                component: () => import('@pages/Grupo/GrupoShow')
            },
            {
                path: 'empresa/edit/:id',
                props: true,
                name: 'EmpresaEdit',
                meta: {
                    title: "Edição de Empresa",
                    permissions: ['empresa_editar']
                },
                component: () => import('@pages/Empresa/EmpresaEdit')
            },
            {
                path: 'empresa/show',
                name: 'EmpresaShow',
                meta: {
                    title: "Visualizar Empresa",
                    permissions: ['empresa_visualizar']
                },
                component: () => import('@pages/Empresa/EmpresaShow')
            },
            {
                path: 'configfiscal/edit/',
                name: 'ConfigFiscalEdit',
                meta: {
                    title: "Edição de Configurações Fiscais",
                    permissions: ['configfiscal_editar']
                },
                component: () => import('@pages/ConfigFiscal/ConfigFiscalEdit')
            },
            {
                path: 'configfiscal/certificado/',
                name: 'ConfigFiscalEditCertificado',
                meta: {
                    title: "Edição de Configurações Fiscais",
                    permissions: ['configfiscal_editar']
                },
                component: () => import('@pages/ConfigFiscal/ConfigFiscalCertificado')
            },
            {
                path: 'configfiscal/show',
                name: 'ConfigFiscalShow',
                meta: {
                    title: "Visualizar Configurações Fiscais",
                    permissions: ['configfiscal_visualizar']
                },
                component: () => import('@pages/ConfigFiscal/ConfigFiscalShow')
            },
            {
                path: 'nfse',
                name: 'NFSeShow',
                meta: {
                    title: "Notas Fiscais Emitidas",
                    permissions: ['nfse_visualizar']
                },
                component: () => import('@pages/NFSe/NFSe')
            },
            {
                path: 'nfseemitir/:tipo/:id',
                props: true,
                name: 'NFSeEmitir',
                meta: {
                    title: "Notas Fiscais Emissão",
                    permissions: ['nfse_visualizar']
                },
                component: () => import('@pages/NFSe/NFSeEmitir')
            },

            {
                path: 'setor',
                name: 'Setor',
                meta: {
                    title: "Setores",
                    permissions: ['setor_visualizar']
                },
                component: () => import('@pages/Setor/Setor')
            },
            {
                path: 'setor/create',
                name: 'SetorCreate',
                meta: {
                    title: "Cadastro de Setor",
                    permissions: ['setor_inserir']
                },
                component: () => import('@pages/Setor/SetorCreate')
            },
            {
                path: 'setor/edit/:id',
                props: true,
                name: 'SetorEdit',
                meta: {
                    title: "Edição de Setor",
                    permissions: ['setor_editar']
                },
                component: () => import('@pages/Setor/SetorEdit')
            },
            {
                path: 'modulo',
                name: 'Modulo',
                meta: {
                    title: "Módulos",
                    permissions: ['modulo_visualizar']
                },
                component: () => import('@pages/Modulo/Modulo')
            },
            {
                path: 'modulo/show/:id',
                props: true,
                name: 'ModuloShow',
                meta: {
                    title: "Visualizar Módulos",
                    permissions: ['modulo_visualizar']
                },
                component: () => import('@pages/Modulo/ModuloShow')
            },
            {
                path: 'modulo/create',
                name: 'ModuloCreate',
                meta: {
                    title: "Cadastro de Módulo",
                    permissions: ['modulo_inserir']
                },
                component: () => import('@pages/Modulo/ModuloCreate')
            },
            {
                path: 'modulo/edit/:id',
                props: true,
                name: 'ModuloEdit',
                meta: {
                    title: "Edição de Módulo",
                    permissions: ['modulo_editar']
                },
                component: () => import('@pages/Modulo/ModuloEdit')
            },
            {
                path: 'oportunidade',
                props: true,
                name: 'Oportunidade',
                meta: {
                    title: "Oportunidades",
                    permissions: ['oportunidade_visualizar']
                },
                component: () => import('@pages/Oportunidade/Oportunidade')
            },
            {
                path: 'oportunidade/create',
                name: 'OportunidadeCreate',
                meta: {
                    title: "Oportunidades",
                    permissions: ['oportunidade_inserir']
                },
                component: () => import('@pages/Oportunidade/OportunidadeCreate')
            },
            {
                path: 'oportunidade/edit/:id',
                props: true,
                name: 'OportunidadeEdit',
                meta: {
                    title: "Oportunidades",
                    permissions: ['oportunidade_editar']
                },
                component: () => import('@pages/Oportunidade/OportunidadeEdit')
            },
            {
                path: 'oportunidade/show/:id',
                props: true,
                name: 'OportunidadeShow',
                meta: {
                    title: "Oportunidades",
                    permissions: ['oportunidade_visualizar']
                },
                component: () => import('@pages/Oportunidade/OportunidadeShow')
            },
            {
                path: 'oportunidade/financeiro/:id',
                props: true,
                name: 'OportunidadeFinanceiro',
                meta: {
                    title: "Oportunidades",
                    permissions: ['oportunidade_financeiro']
                },
                component: () => import('@pages/Oportunidade/OportunidadeFinanceiro')
            },
            {
                path: 'oportunidade/financeiro/create/:id',
                props: true,
                name: 'OportunidadeFinanceiroCreate',
                meta: {
                    title: "Oportunidades",
                    permissions: ['oportunidade_financeiro']
                },
                component: () => import('@pages/Oportunidade/OportunidadeFinanceiroCreate')
            },
            {
                path: 'implantacao/financeiro/:id',
                props: true,
                name: 'ImplantacaoFinanceiro',
                meta: {
                    title: "Implantacaos",
                    permissions: ['cliente_financeiro']
                },
                component: () => import('@pages/Implantacao/ImplantacaoFinanceiro')
            },
            {
                path: 'implantacao/financeiro/create/:id',
                props: true,
                name: 'ImplantacaoFinanceiroCreate',
                meta: {
                    title: "Implantacaos",
                    permissions: ['cliente_financeiro']
                },
                component: () => import('@pages/Implantacao/ImplantacaoFinanceiroCreate')
            },
            {
                path: 'oportunidade/etapa/:id',
                props: true,
                name: 'OportunidadeEtapa',
                meta: {
                    title: "Oportunidades",
                    permissions: ['oportunidade_editar']
                },
                component: () => import('@pages/Oportunidade/OportunidadeEtapa')
            },

            {
                path: 'perfil',
                name: 'Perfil',
                meta: {
                    title: "Perfil de Acesso",
                    permissions: ['perfil_acesso_visualizar']
                },
                component: () => import('@pages/PerfilAcesso/Perfil')
            },
            {
                path: 'perfil/create',
                name: 'PerfilCreate',
                meta: {
                    title: "Cadastro de Perfil",
                    permissions: ['perfil_acesso_inserir']
                },
                component: () => import('@pages/PerfilAcesso/PerfilCreate')
            },
            {
                path: 'perfil/edit/:id',
                props: true,
                name: 'PerfilEdit',
                meta: {
                    title: "Edição de Perfil",
                    permissions: ['perfil_acesso_editar']
                },
                component: () => import('@pages/PerfilAcesso/PerfilEdit')
            },
            {
                path: 'permissao/:id',
                props: true,
                name: 'Permissao',
                meta: {
                    title: "Permissões de Acesso",
                    permissions: ['perfil_acesso_editar']
                },
                component: () => import('@pages/PerfilAcesso/Permissao')
            },

            {
                path: 'formulario',
                name: 'Formulario',
                meta: {
                    title: "Formulários",
                    permissions: ['formulario_visualizar']
                },
                component: () => import('@pages/Formulario/Formulario')
            },
            {
                path: 'formulario/show/:id',
                props: true,
                name: 'FormularioShow',
                meta: {
                    title: "Visualizar Formulários",
                    permissions: ['formulario_visuliazar']
                },
                component: () => import('@pages/Formulario/FormularioShow')
            },
            {
                path: 'formulario/create',
                name: 'FormularioCreate',
                meta: {
                    title: "Cadastro de Formulário",
                    permissions: ['formulario_inserir']
                },
                component: () => import('@pages/Formulario/FormularioCreate')
            },
            {
                path: 'formulario/edit/:id',
                props: true,
                name: 'FormularioEdit',
                meta: {
                    title: "Edição de Formulário",
                    permissions: ['formulario_editar']
                },
                component: () => import('@pages/Formulario/FormularioEdit')
            },
            {
                path: 'formulario/cliente/show/:id/:formulario_oportunidade_id',
                props: true,
                name: 'FormularioClienteShow',
                meta: {
                    title: "Visualizar Formulário",
                    permissions: ['formulario_show']
                },
                component: () => import('@pages/Formulario/FormularioClienteShow')
            },
            {
                path: 'formulario/qualificacao/show/:cliente_id/:formulario_qualificacao_id',
                props: true,
                name: 'FormularioQualificacaoShow',
                meta: {
                    title: "Visualizar Formulário",
                    permissions: ['formulario_show']
                },
                component: () => import('@pages/Cliente/FormularioQualificacaoShow')
            },

            {
                path: 'formulario-user',
                name: 'FormularioUser',
                meta: {
                    title: "Formulários Internos",
                    permissions: ['formulario_visualizar']
                },
                component: () => import('@pages/FormularioUser/Formulario')
            },
            {
                path: 'formulario-user/show/:id',
                props: true,
                name: 'FormularioUserShow',
                meta: {
                    title: "Visualizar Formulário Interno",
                    permissions: ['formulario_visuliazar']
                },
                component: () => import('@pages/FormularioUser/FormularioShow')
            },
            {
                path: 'formulario-user/respostas/:id',
                props: true,
                name: 'FormularioUserRespostasList',
                meta: {
                    title: "Respostas do Formulário Interno",
                },
                component: () => import('@pages/FormularioUser/RespostasList')
            },
            {
                path: 'formulario-user/create',
                name: 'FormularioUserCreate',
                meta: {
                    title: "Cadastro de Formulário Interno",
                    permissions: ['formulario_inserir']
                },
                component: () => import('@pages/FormularioUser/FormularioCreate')
            },
            {
                path: 'formulario-user/edit/:id',
                props: true,
                name: 'FormularioUserEdit',
                meta: {
                    title: "Edição de Formulário Interno",
                    permissions: ['formulario_editar']
                },
                component: () => import('@pages/FormularioUser/FormularioEdit')
            },
            {
                path: 'pesquisa/',
                props: true,
                name: 'PesquisaUser',
                meta: {
                    title: "Pesquisa Interna",
                },
                component: () => import('@pages/PesquisaUser/Index')
            },
            {
                path: 'modelosderelatorio',
                name: 'ModelosDeRelatorio',
                meta: {
                    title: "Modelos de Relatórios",
                    permissions: ['modelo_relatorio_visualizar']
                },
                component: () => import('@pages/ModelosDeRelatorio/ModelosDeRelatorio')
            },
            {
                path: 'modelosderelatorio/show/:id',
                props: true,
                name: 'ModelosDeRelatorioShow',
                meta: {
                    title: "Visualizar Formulários",
                    permissions: ['modelo_relatorio_visualizar'],
                },
                component: () => import('@pages/ModelosDeRelatorio/ModelosDeRelatorioShow')
            },
            {
                path: 'modelosderelatorio/create',
                name: 'ModelosDeRelatorioCreate',
                meta: {
                    title: "Cadastro de Formulário",
                    permissions: ['modelo_relatorio_inserir']
                },
                component: () => import('@pages/ModelosDeRelatorio/ModelosDeRelatorioCreate')
            },
            {
                path: 'modelosderelatorio/edit/:id',
                props: true,
                name: 'ModelosDeRelatorioEdit',
                meta: {
                    title: "Edição de Formulário",
                    permissions: ['modelo_relatorio_editar']
                },
                component: () => import('@pages/ModelosDeRelatorio/ModelosDeRelatorioEdit')
            },

            {
                path: 'modelosdecontrato',
                name: 'ModelosDeContrato',
                meta: {
                    title: "Modelos de Contratos",
                    permissions: ['modelo_contrato_visualizar']
                },
                component: () => import('@pages/ModelosDeContrato/ModelosDeContrato')
            },
            /* {
                path: 'modelosdecontrato/show/:id',
                props: true,
                name: 'ModelosDeContratoShow',
                meta: {title: "Visualizar Contratos"},
                component: () => import('@pages/ModelosDeContrato/ModelosDeContratoShow')
            }, */
            {
                path: 'modelosdecontrato/create',
                name: 'ModelosDeContratoCreate',
                meta: {
                    title: "Cadastro de Contrato",
                    permissions: ['modelo_contrato_inserir']
                },
                component: () => import('@pages/ModelosDeContrato/ModelosDeContratoCreate')
            },
            {
                path: 'modelosdecontrato/edit/:id',
                props: true,
                name: 'ModelosDeContratoEdit',
                meta: {
                    title: "Edição de Formulário",
                    permissions: ['modelo_contrato_editar']
                },
                component: () => import('@pages/ModelosDeContrato/ModelosDeContratoEdit')
            },

            {
                path: 'Agenda',
                name: 'Agenda',
                meta: {
                    title: "Agenda",
                    permissions: ['agenda_visualizar']
                },
                component: () => import('@pages/Agenda/Agenda')
            },

            {
                path: 'meu-cadastro',
                props: true,
                name: 'UsuarioPerfil',
                meta: {
                    title: "Perfil do Usuário",
                },
                component: () => import('@pages/Perfil/UsuarioPerfil')
            },
            {
                path: 'meu-cadastro/senha',
                props: true,
                name: 'UsuarioSenha',
                meta: {
                    title: "Alteração de Senha",
                },
                component: () => import('@pages/Perfil/UsuarioSenha')
            },
            {
                path: 'chat',
                props: true,
                name: 'Chat',
                meta: {
                    title: "Chat"
                },
                component: () => import('@pages/Chat/Chat')
            },

            {
                path: 'sala',
                name: 'Sala',
                meta: {
                    title: "Salas",
                    permissions: ['sala_chat_visualizar']
                },
                component: () => import('@pages/Sala/Sala')
            },
            {
                path: 'sala/create',
                name: 'SalaCreate',
                meta: {
                    title: "Cadastro de Sala",
                    permissions: ['sala_chat_inserir']
                },
                component: () => import('@pages/Sala/SalaCreate')
            },
            {
                path: 'sala/edit/:id',
                props: true,
                name: 'SalaEdit',
                meta: {
                    title: "Edição de Sala",
                    permissions: ['sala_chat_editar']
                },
                component: () => import('@pages/Sala/SalaEdit')
            },
            {
                path: 'sala/show/:id',
                props: true,
                name: 'SalaShow',
                meta: {
                    title: "Visualizar Sala",
                    permissions: ['sala_chat_visualizar']
                },
                component: () => import('@pages/Sala/SalaShow')
            },

            {
                path: 'ata',
                name: 'AtaReuniao',
                meta: {
                    title: "Atas de Reunião",
                    permissions: ['ata_reuniao_visualizar']
                },
                component: () => import('@pages/Ata/Ata')
            },
            {
                path: 'ata/create',
                name: 'AtaCreate',
                meta: {
                    title: "Cadastro de Ata de Reunião",
                    permissions: ['ata_reuniao_inserir']
                },
                component: () => import('@pages/Ata/AtaCreate')
            },
            {
                path: 'ata/edit/:id',
                props: true,
                name: 'AtaEdit',
                meta: {
                    title: "Edição de Ata de Reunião",
                    permissions: ['ata_reuniao_editar']
                },
                component: () => import('@pages/Ata/AtaEdit')
            },
            {
                path: 'ata/show/:id',
                props: true,
                name: 'AtaShow',
                meta: {
                    title: "Visualizar Ata de Reunião",
                    permissions: ['ata_reuniao_visualizar']
                },
                component: () => import('@pages/Ata/AtaShow')
            },

            {
                path: 'tarefa',
                name: 'Tarefa',
                meta: {
                    title: "Tarefas",
                    permissions: ['tarefa_visualizar']
                },
                component: () => import('@pages/Tarefa/Tarefa')
            },
            {
                path: 'minhas-tarefas',
                name: 'TarefaUser',
                meta: {
                    title: "Tarefas"
                },
                component: () => import('@pages/Tarefa/TarefaUser')
            },
            {
                path: 'tarefa/create/:id?',
                props: true,
                name: 'TarefaCreate',
                meta: {
                    title: "Cadastro de Tarefa",
                    permissions: ['tarefa_inserir']
                },
                component: () => import('@pages/Tarefa/TarefaCreate')
            },
            {
                path: 'tarefa/edit/:id',
                props: true,
                name: 'TarefaEdit',
                meta: {
                    title: "Edição de Tarefa",
                    permissions: ['tarefa_editar']
                },
                component: () => import('@pages/Tarefa/TarefaEdit')
            },
            {
                path: 'tarefa/show/:id',
                props: true,
                name: 'TarefaShow',
                meta: {
                    title: "Visualizar Tarefa",
                    permissions: ['tarefa_visualizar']
                },
                component: () => import('@pages/Tarefa/TarefaShow')
            },

            {
                path: 'gruporelatorio/create',
                name: 'GrupoRelatorioCreate',
                meta: {
                    title: "Cadastro de Grupo de Relatório",
                    permissions: ['gruporelatorio_inserir']
                },
                component: () => import('@pages/GrupoRelatorio/GrupoRelatorioCreate')
            },
            {
                path: 'gruporelatorio/edit/:id',
                props: true,
                name: 'GrupoRelatorioEdit',
                meta: {
                    title: "Edição de Grupo de Relatório",
                    permissions: ['gruporelatorio_editar']
                },
                component: () => import('@pages/GrupoRelatorio/GrupoRelatorioEdit')
            },

            {
                path: 'grupoformulario/create',
                name: 'GrupoFormularioCreate',
                meta: {
                    title: "Cadastro de Grupo de Formulário",
                    permissions: ['grupoformulario_inserir']
                },
                component: () => import('@pages/GrupoFormulario/GrupoFormularioCreate')
            },
            {
                path: 'grupoformulario/edit/:id',
                props: true,
                name: 'GrupoFormularioEdit',
                meta: {
                    title: "Edição de Grupo de Formulário",
                    permissions: ['grupoformulario_editar']
                },
                component: () => import('@pages/GrupoFormulario/GrupoFormularioEdit')
            },

            {
                path: 'relatorio/cliente',
                props: true,
                name: 'RelatorioCliente',
                meta: {
                    title: "Relatório de Clientes",
                    permissions: ['relatorio_cliente_visualizar']
                },
                component: () => import('@pages/Relatorio/RelatorioCliente')
            },
            {
                path: 'relatorio/oportunidade',
                props: true,
                name: 'RelatorioOportunidade',
                meta: {
                    title: "Relatório de Oportunidades",
                    permissions: ['relatorio_oportunidade_visualizar']
                },
                component: () => import('@pages/Relatorio/RelatorioOportunidade')
            },
            {
                path: 'relatorio/etapa',
                props: true,
                name: 'RelatorioEtapa',
                meta: {
                    title: "Relatório de Etapas",
                    permissions: ['relatorio_etapa_visualizar']
                },
                component: () => import('@pages/Relatorio/RelatorioEtapa')
            },
            {
                path: 'relatorio/suporte',
                props: true,
                name: 'RelatorioSuporte',
                meta: {
                    title: "Relatório de Suporte",
                    permissions: ['relatorio_suporte_visualizar']
                },
                component: () => import('@pages/Relatorio/RelatorioSuporte')
            },
            {
                path: 'relatorio/treinamento',
                props: true,
                name: 'RelatorioTreinamento',
                meta: {
                    title: "Relatório de Treinamento",
                    permissions: ['relatorio_treinamento_visualizar']
                },
                component: () => import('@pages/Relatorio/RelatorioTreinamento')
            },
            {
                path: 'relatorio/financeiro',
                props: true,
                name: 'RelatorioFinanceiro',
                meta: {
                    title: "Relatório Financeiro",
                    permissions: ['relatorio_financeiro_visualizar']
                },
                component: () => import('@pages/Relatorio/RelatorioFinanceiro')
            },
        ]
    },
    {
        path: "/login",
        name: "Login",
        component: () => import('@pages/Auth/Login'),
        //meta: {guest: true},
    },
    {
        path: "/print",
        name: "Print",
        component: () => import('@pages/Relatorio/Print/Print'),
    },
    {
        path: "/formulario/:hash",
        props: true,
        name: "FormularioCliente",
        component: () => import('@pages/Formulario/FormularioCliente'),
    },
    {
        path: "/formularioqualificacao/:hash",
        props: true,
        name: "FormularioQualificacao",
        component: () => import('@pages/Cliente/FormularioQualificacao'),
    },
]


const router = new VueRouter({
    mode: "history",
    routes
})

function loggedIn() {
    return store.state.isLogged;
}


const DEFAULT_TITLE = 'Gueiros';
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
})


router.beforeEach((to, from, next) => {
    const userPermissions = store.state.user && store.state.user.perfil ? store.state.user.perfil.permissoesList : [];
    console.log(store.state.user);

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!loggedIn()) {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            })
        }
        else {

            if (to.meta.permissions && to.meta.permissions.length > 0) {
                if (!store.state.user.admin) {
                    let isAllowed = userPermissions.some(p => to.meta.permissions.includes(p))
                    if (!isAllowed) {
                        store.state.modalNaoPermitido = true;
                        return
                    }
                }
            }

            if(store.state.user && store.state.user.formulariosPendentes > 0){

                if (to.name !== 'PesquisaUser') {
                    next({ name: 'PesquisaUser' });
                } else {
                    next();
                }

            }

            next()
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (loggedIn()) {
            next({
                path: '/',
                query: { redirect: to.fullPath }
            })
        }
        else {
            /* if (to.meta.permissions && to.meta.permissions.length > 0) {
                let isAllowed = userPermissions.some(p => to.meta.permissions.includes(p))

                if (! isAllowed) return next('/not-found')
            } */
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router
